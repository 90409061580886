<template>
    <NuxtLink
        :to="`/${$config.public.variant !== 'cupohosting' ? `${$lang.routes.brands}/` : ''}${info.slug}`"
        class="new-homepage-grid-card"
    >
        <picture class="new-homepage-grid-card__picture">
            <img
                class="new-homepage-grid-card__picture-image"
                v-if="info.image"
                :src="info.image.url || defaultImage"
                :alt="`Logo de ${info.name}`"
                :onerror="`this.onerror=null;this.src='${defaultStoreImage}'`"
                :title="`Logo de ${info.name}`"
            />
        </picture>
        <p class="new-homepage-grid-card__store">{{ info.name }}</p>
        <h3 class="new-homepage-grid-card__title">{{ info.coupontitle || info.name }}</h3>
    </NuxtLink>
</template>

<script lang="ts" setup>
import type { Models } from '~/types/models'

const props = defineProps({
    info: {
        type: Object as PropType<Models.Store>,
        required: true,
    },
    lazyImage: Boolean,
    expire: {
        type: Boolean,
        default: false,
    },
    noAuth: {
        type: Boolean,
        default: false,
    },
})

const { defaultImages } = useVariantsDictionary()

const defaultImage = defaultImages.store
const defaultStoreImage = defaultImages.store
</script>

<style lang="postcss">
.new-homepage-grid-card {
    @apply flex h-full w-full flex-col items-center rounded bg-white pt-[25px];

    &__picture {
        @apply mb-[5px] block size-[88px] md:size-[99px];

        &-image {
            @apply block size-full object-contain;
        }
    }

    &__store {
        @apply mb-[5px] text-[15px] font-semibold text-black;
    }

    &__title {
        @apply mt-auto w-full rounded-[2px] bg-site-primary py-[5px] text-center text-lg font-medium text-site-primary-text-contrast;
    }
}
</style>
